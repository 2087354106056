const env = process.env.NODE_ENV || "development";

const apiEnvironment = {
  //DOMAIN SERVER MỚI
  development: {
    api: "https://sontay-api.cgis.asia/",
    domainUserSide: "http://localhost:3000",
    domainAdminSide: "http://localhost:3000",
    domainName: "",
    doMainNameGeoServer: "https://geo.cgis.asia/geoserver/wfs",
    doMainNameGeoServerWMS: "https://geo.cgis.asia/geoserver/wms",
    datastore: 'sontay',
    gwcUrl: 'https://geo.cgis.asia/geoserver/gwc/service/wms/'

  },
  production: {
    api: "https://sontay-api.cgis.asia/",
    domainUserSide: "https://sontay.cgis.asia",
    domainAdminSide: "https://admin.sontay.cgis.asia",
    domainName: "sontay.cgis.asia",
    doMainNameGeoServer: "https://geo.cgis.asia/geoserver/wfs",
    doMainNameGeoServerWMS: "https://geo.cgis.asia/geoserver/wms",
    datastore: 'sontay',
    gwcUrl: 'https://geo.cgis.asia/geoserver/gwc/service/wms/'
  },


  // geo.cgis.asia - geo cũ
  // geo-phutho.cgis.asia -geo mới

  // //DOMAIN SERVER CŨ
  // development: {
  //   api: "https://phutho-api.cgis.asia/",
  //   domainUserSide: "http://localhost:3000",
  //   // domainAdminSide: "https://admin.phutho.cgis.asia",
  //   domainAdminSide: "http://localhost:3000",
  //   domainName: "",
  //   doMainNameGeoServer: "https://geo-phutho.cgis.asia/geoserver/wfs",
  //   doMainNameGeoServerWMS: "https://geo-phutho.cgis.asia/geoserver/wms",
  //   datastore: 'phutho',
  // },
  // production: {
  //   api: "https://phutho-api.cgis.asia/",
  //   domainUserSide: "http://phutho.cgis.asia",
  //   domainAdminSide: "https://admin.phutho.cgis.asia",
  //   domainName: "phutho.cgis.asia",
  //   doMainNameGeoServer: "https://geo-phutho.cgis.asia/geoserver/wfs",
  //   doMainNameGeoServerWMS: "https://geo-phutho.cgis.asia/geoserver/wms",
  //   datastore: 'phutho',
  // },
};

module.exports = apiEnvironment[env];
