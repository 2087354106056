export default {
  planningMapView: {
    baseMap: "Bản đồ nền",
    toolsMap: "Công cụ bản đồ",
    googleMap: "Bản đồ Google",
    satelliteMap: "Bản đồ vệ tinh",
    notUseBaseMap: "Không dùng bản đồ nền",
    mapToolsPanel: {
      viewObjectInfomation: "Xem thông tin đối tượng",
      toggleFullScreen: "Mở rộng/Thu nhỏ",
      zoomIn: "Phóng to bản đồ",
      zoomOut: "Thu nhỏ bản đồ",
      filterInfomation: "Lọc thông tin",
      mapLegend: "Chú giải bản đồ",
      selectAnObject: "Chọn một đối tượng",
      selectMultiObject: "Chọn nhiều đối tượng",
      measuareArea: "Đo diện tích",
      measuareDistance: "Đo khoảng cách",
      moveViewport: "Di chuyển khung nhìn",
      printTheMap: "In bản đồ",
      myPosition: "Xác định vị trí của bạn",
      exportMap: "Xuất bản đồ",
      compare: "So sánh",
      locationMarker: "Đánh dấu vị trí",
    },
    filterInfomationPopup: {
      headTitle: "Tìm kiếm đối tượng",
      labelSelectSearch: "Chọn đối tượng tìm kiếm",
      searchInLine: "Tìm kiếm trên đường",
      searchInArea: "Tìm kiếm trong vùng",
      searchInCircle: "TÌm kiếm trong đường tròn",
      searchInSquare: "Tìm kiếm trong hình chữ nhật",
      searchWithAttribute: "Theo thuộc tính",
      search: "Tìm kiếm",
      delete: "Xóa",
      close: "Đóng",
    },
  },
  planningMapView_guideToUse: "Hướng dẫn sử dụng",
  planningMapView_guideToUse_category: "Danh mục hướng dẫn",
  planningMapView_map: "Bản đồ",
  planningMapView_infomation: "Thông tin",
  planningMapView_relatedPlanning: "Quy hoạch liên quan",
  planningMapView_result: "Kết quả",
  header_homePage: "Trang chủ",
  header_pleaseConsultTheCommunity: "Xin ý kiến cộng đồng",
  header_lookUpPlanning: "Tra cứu quy hoạch",
  header_news: "Tin tức",
  header_information: "Thông tin",
  header_planningAnnouncement: "Công bố quy hoạch",
  header_recordsManager: "Quản lý hồ sơ",
  header_reflect: "Phản ánh hiện trường",
  header_map: "Bản đồ",
  header_Contact: "Liên hệ",
  header_generalPlanning: "Quy hoạch chung",
  header_syntheticPlanning: "Tổng hợp quy hoạch",
  header_cityPlanning: "Quy hoạch theo Huyện/Thành phố",
  header_subdivisionPlanning: "Quy hoạch phân khu",
  header_detailedPlanning: "Quy hoạch chi tiết",
  header_specializedPlanning: "Quy hoạch chuyên ngành",
  header_administration: "Quản trị",
  header_logout: "Đăng xuất",
  header_logIn: "Đăng Nhập",
  user_info: "Thông tin cá nhân",
  reflect_recommendations: "Phản ánh kiến nghị",
  header_myReflect: "Phản ánh của bạn",
  change_password: "Đổi mật khẩu",
  header_account: "Tài khoản",
  header_title: "GIS Tiên Yên",
  footer_titleName: "TRANG THÔNG TIN ĐIỆN TỬ UBND Thị xã SƠN TÂY",
    footer_email: "vanthu_sontay@hanoi.gov.vn",
    footer_email_to: "vanthu_sontay@hanoi.gov.vn",
    footer_address: "Số 1, Đường Phó Đức Chính, Thị xã Sơn Tây, Thành Phố Hà Nội",
    footer_hotline: "0433832210",
    footer_hotline_tel: "0433832210",
    footer_fax: "",
    footer_website: "http://sontay.hanoi.gov.vn/",
    footer_website_to: "http://sontay.hanoi.gov.vn/", 
    contact_map_center_lat: 20.827501724727288,
    contact_map_center_lng: 105.34172903977286,
    contact_map_marker_lat: 20.827501724727288,
    contact_map_marker_lng: 105.34172903977286,
    contact_map_url: "https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d119084.96820207726!2d105.50121045869138!3d21.13626470542659!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1zdWJuZCBzxqFuIHTDonk!5e0!3m2!1svi!2s!4v1701315371421!5m2!1svi!2s",
};
