import React from 'react';
const IMG_DEFAULT = "../bg-login.jpg"

export const LeftPopup = (props) => {
  const { img } = props;
  return (
    <div className="col-12 col-lg-6 left-register">
      <img src={img || IMG_DEFAULT} alt="" style={{width: '100%', height: '100%'}}/>
    </div>
  )
}